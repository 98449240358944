import React, { Component } from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Intro from "../components/Intro";
import Umfeld from "../components/Umfeld";
import Lage from "../components/Lage";
import Gruende from "../components/Gruende";
import Ausstattung from "../components/Ausstattung";
import Geschosse from "../components/Geschosse";
import Downloads from "../components/Downloads";
import Kontakt from "../components/Kontakt";
import Footer from "../components/Footer";
import Gruende2 from '../components/Gruende2';
import Video from '../components/Video'
import Fade from "react-reveal";

class PageIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="App">
        <Header />
        <Hero />
        <Intro />
        <Gruende2 />
        <Ausstattung />
        <Video />
        <Geschosse />
        <Gruende />
        <Umfeld />
        <Lage />
        <Downloads />
        <Kontakt />
        <Footer />
      </div>
    );
  }
}

export default PageIndex;
