import React from "react";
import YouTube from 'react-youtube';

export default function Video() {

  const opts = {
    height: '500',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (<section id='youtube'>

    <YouTube videoId="MgOI6KXr-Og" opts={opts} />


  </section>)
}

