import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

class Overlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: false
    };
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.data !== this.props.data) {
      this._imageGallery.slideToIndex(0);
      this.setState({ hidden: true }, () => {
        setTimeout(() => {
          this.setState({ hidden: false });
        }, 500);
      });
    }
  }

  render() {
    const { showRooms, hidden } = this.state;
    const { data } = this.props;
    let inneroverlayclass = "inner";
    if (hidden) {
      inneroverlayclass = "inner hidden";
    }
    return (
      <div className="overlay">
        <div className={inneroverlayclass}>
          <div>
            <div>
              <div
                className="overlay-arrow left"
                onClick={() => this.props.prevImage()}
              />
              <div
                className="overlay-arrow right"
                onClick={() => this.props.nextImage()}
              />
              <div
                className="overlay-close"
                onClick={() => this.props.close()}
              />
              <h2>
                {data.beschreibung}
                <br />
              </h2>
              <span className="sub-wohnung">{data.typ}</span>
            </div>
            <div className="content">
              <div className="overlay-facts">Fakten & Zahlen</div>
              <div>
                Wohnfläche{" "}
                <span className="number"> ca. {data.wohnflaeche} m²</span>
              </div>
              <div>
                Zimmer <span className="number">{data.zimmer}</span>
              </div>
              {data.zimmerDetail.length && showRooms ? (
                <div className="room-block">
                  <div
                    className="hide-rooms"
                    onClick={() => this.setState({ showRooms: false })}
                  >
                    Zimmer ausblenden
                  </div>
                  {data.zimmerDetail.map((zimmer, idx) => {
                    return (
                      <div>
                        {zimmer.text}{" "}
                        <span className="number">
                          ca. {zimmer.size.replace(".", ",")}m²
                        </span>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div
                  className="show-rooms"
                  onClick={() => this.setState({ showRooms: true })}
                >
                  Zimmer anzeigen
                  <span />
                </div>
              )}{ data.kaufpreis &&
              <div className="price">
                Kaufpreis{" "}
                <span className="number">
                  ab {data.kaufpreis.toLocaleString()} €
                </span>
              </div>
  }
            </div>
          </div>
          <div>
            <ImageGallery
              ref={i => (this._imageGallery = i)}
              showNav={false}
              showPlayButton={false}
              items={data.bilder}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Overlay;
