import React, { Component } from "react";
import Fade from "react-reveal";
import Wohnung from "./wohnung";
import Overlay from "./overlay";

class Geschoss extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  showOverlay(wohnung) {
    this.setState({
      overlay: true,
      activeWohnung: wohnung
    });
  }
  nextImage = () => {
    if (this.state.activeWohnung + 1 === this.props.data.wohnungen.length) {
      this.setState({
        activeWohnung: 0
      });
    } else {
      this.setState({
        activeWohnung: this.state.activeWohnung + 1
      });
    }
  };
  prevImage = () => {
    if (this.state.activeWohnung === 0) {
      this.setState({
        activeWohnung: this.props.data.wohnungen.length - 1
      });
    } else {
      this.setState({
        activeWohnung: this.state.activeWohnung - 1
      });
    }
  };
  render() {
    const { data } = this.props;
    const { overlay, activeWohnung } = this.state;
    return (
      <section id="geschosse">
        <h2>{data.bezeichnung}</h2>
        {data.splash && <div className='geschosse-splash'>{data.splash}
        </div>}
        <div className="content">{data.beschreibung}</div>
        <div className="geschoss-grid">
          {data.wohnungen
            ? data.wohnungen.map((wohnung, idx) => {
              return (
                <div onClick={() => this.showOverlay(idx)} key={idx}>
                  <Wohnung
                    key={idx}
                    geschoss={data.bezeichnung}
                    data={wohnung}
                  />
                </div>
              );
            })
            : ""}
        </div>
        {overlay ? (
          <Overlay
            nextImage={this.nextImage}
            prevImage={this.prevImage}
            data={data.wohnungen[activeWohnung]}
            close={() => this.setState({ overlay: false })}
          />
        ) : (
            ""
          )}
      </section>
    );
  }
}

export default Geschoss;
