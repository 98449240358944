import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <footer>
        <section id="footer">
          <div className="footer-partner">
            Bitte beachten Sie, dass die vorgestellten Inhalte den aktuellen
            Planungs- und Ausführungsstand von März 2019 repräsentieren.
            Abgebildete Einrichtungsgegenstände gehören nicht zum
            Ausstattungsumfang und sind lediglich als Gestaltungsvorschläge zu
            verstehen. Eine Gewähr für die Vollständigkeit der Informationen
            kann nicht übernommen werden. Verbindlich für die Ausführung sind
            allein die beurkundeten Unterlagen.
          </div>
          <div className="footer-lower">
            © 2019 HTN Immobilien Management GmbH |{" "}
            <Link to="/impressum">Impressum</Link> |{" "}
            <Link to="/datenschutz">Datenschutz</Link>
          </div>
        </section>
      </footer>
    );
  }
}

export default Footer;
