import React, { Component } from "react";
import Ausbild from "../img/ausstattung/ausstattung.jpg";
import Fade from "react-reveal";

class Ausstattung extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <section id="ausstattung">
        <h2>
          Anspruchsvolle Ausstattung
          <br />
          <span className="subline">gehobenes Wohlfühlen.</span>
        </h2>
        <img src={Ausbild} alt="Ausstattung" style={{ marginBottom: "50px" }} />
        <div className="grid-50">
          <ul>
            <li>Offene Architektur</li>
            <li>Großer Balkon/große Terrasse</li>
            <li>Hochwertigste Materialien</li>
            <li>Parkett Eiche Natur</li>
            <li>
              Geringe Energiekosten durch erneuerbare Energien mit
              Solarwärmeanlage
            </li>
            <li>Fußbodenheizung in allen Räumen</li>
            <li>Dreifachverglaste Fenster und bodentiefe Balkonfenster</li>
          </ul>

          <ul>
            <li>Elektrische Rollläden</li>
            <li>TV- und LAN-Anschlüsse in jedem Raum</li>
            <li>Repräsentatives Entrée</li>
            <li>Videosprechanlage inkl. App-Funktion</li>
            <li>Bäder mit bodengleicher Dusche</li>
            <li>Großzügige Tiefgaragenstellplätze mit komfortablem elektrischen Rolltor</li>
            <li>Außenstellplätze</li>

          </ul>
        </div>
      </section>
    );
  }
}

export default Ausstattung;
