import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

class PageDatenschutz extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header />
        <section>
          <p>
            <b>Datenschutzerklärung</b>
          </p>{" "}
          <p>
            <b>1. Datenschutz auf einen Blick</b>
          </p>{" "}
          <p>
            <b>Allgemeine Hinweise</b>
          </p>{" "}
          <p>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was
            mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website
            besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
            persönlich identifiziert werden können. Ausführliche Informationen
            zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
            aufgeführten Datenschutzerklärung.
          </p>{" "}
          <p>
            <b>Datenerfassung auf unserer Website</b>
          </p>{" "}
          <p>
            <b>
              Wer ist verantwortlich für die Datenerfassung auf dieser Website?
            </b>
          </p>{" "}
          <p>
            Die Datenverarbeitung auf dieser Website erfolgt durch den
            Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum
            dieser Website entnehmen.
          </p>{" "}
          <p>
            <b>Wie erfassen wir Ihre Daten?</b>
          </p>{" "}
          <p>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
            mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in
            ein Kontaktformular eingeben.
          </p>{" "}
          <p>
            Andere Daten werden automatisch beim Besuch der Website durch unsere
            IT-Systeme erfasst. Das sind vor allem technische Daten (z.B.
            Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
            Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere
            Website betreten.
          </p>{" "}
          <p>
            <b>Wofür nutzen wir Ihre Daten?</b>
          </p>{" "}
          <p>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
            der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
            Nutzerverhaltens verwendet werden.
          </p>{" "}
          <p>
            <b>Welche Rechte haben Sie bezüglich Ihrer Daten?</b>
          </p>{" "}
          <p>
            Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft,
            Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
            erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung
            oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren
            Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im
            Impressum angegebenen Adresse an uns wenden. Des Weiteren steht
            Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          </p>{" "}
          <p>
            <b>Analyse-Tools und Tools von Drittanbietern</b>
          </p>{" "}
          <p>
            Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch
            ausgewertet werden. Das geschieht vor allem mit Cookies und mit
            sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens
            erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen
            verfolgt werden. Sie können dieser Analyse widersprechen oder sie
            durch die Nichtbenutzung bestimmter Tools verhindern. Detaillierte
            Informationen dazu finden Sie in der folgenden Datenschutzerklärung.
          </p>{" "}
          <p>
            Sie können dieser Analyse widersprechen. Aber die
            Widerspruchsmöglichkeiten werden wir Sie in dieser
            Datenschutzerklärung informieren.
          </p>{" "}
          <p>
            <b>2. Allgemeine Hinweise und Pflichtinformationen</b>
          </p>{" "}
          <p>
            <b>Hinweis zur verantwortlichen Stelle</b>
          </p>{" "}
          <p>
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser
            Website ist:
          </p>{" "}
          <p>
            {" "}
            <strong>Angaben gemäß § 5 TMG</strong>
            <br />
            HTN Immobilien Management GmbH
            <br />
            Höher Straße 4<br /> 42655 Solingen
          </p>
          <p>
            Verantwortliche Stelle ist die natürliche oder juristische Person,
            die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
            Verarbeitung von personenbezogenen Daten (z.B. Namen,
            E-Mail-Adressen o. Ä.) entscheidet.
          </p>{" "}
          <p>
            <b>Widerruf Ihrer Einwilligung zur Datenverarbeitung</b>
          </p>{" "}
          <p>
            Viele Datenschutzverarbeitungsvorgänge sind nur mit Ihrer
            ausdrücklichen Einwilligung möglich. Sie können eine bereits
            erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine
            formlose Mitteilung per E-Mail an uns. Die Rechtsmäßigkeit der bis
            zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
            unberührt.
          </p>{" "}
          <p>
            <b>Beschwerderecht bei der zuständigen Aufsichtsbehörde</b>
          </p>{" "}
          <p>
            Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein
            Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige
            Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der
            Landesdatenschutzbeauftragte des Bundeslandes, in dem unser
            Unternehmen seinen Sitz hat. Eine Liste der Datenschutzbeauftragten
            sowie deren Kontaktdaten können folgendem Link entnommen werden:{" "}
            <u>
              <a
                href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                target="_blank"
              >
                https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
              </a>
            </u>
            .
          </p>{" "}
          <p>
            <b>Recht auf Datenübertragbarkeit</b>
          </p>{" "}
          <p>
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
            oder in ErfÃ¼llung eines Vertrags automatisiert verarbeiten, an sich
            oder an einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
            an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
            soweit es technisch machbar ist.
          </p>{" "}
          <p>
            <b>SSL- bzw. TLS-Verschlüsselung</b>
          </p>{" "}
          <p>
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
            Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
            oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine
            SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung
            erkennen Sie daran, dass die Adresszeile des Browsers von http://
            auf https:// wechselt und an dem Schloss-Symbol in Ihrer
            Browserzeile.
          </p>{" "}
          <p>
            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
            Daten, die Sie an uns Ã¼bermitteln, nicht von Dritten mitgelesen
            werden.
          </p>{" "}
          <p>
            <b>Auskunft, Sperrung, Löschung</b>
          </p>{" "}
          <p>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
            jederzeit das Recht auf unentgeltliche Auskunft über Ihre
            gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
            und den Zweck der Datenverarbeitung und ggf. ein Recht auf
            Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu
            weiteren Fragen zum Thema personenbezogene Daten können Sie sich
            jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
          </p>{" "}
          <p>
            <b>3. Datenerfassung auf unserer Website</b>
          </p>{" "}
          <p>
            <b>Cookies</b>
          </p>{" "}
          <p>
            Die Internetseiten verwenden teilweise so genannte Cookies. Cookies
            richten auf Ihrem Rechner keinen Schaden an und enthalten keine
            Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher,
            effektiver und sicherer zu machen. Cookies sind kleine Textdateien,
            die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.
          </p>{" "}
          <p>
            Die meisten der von uns verwendeten Cookies sind so genannte
            Session-Cookies. Sie werden nach Ende Ihres Besuchs automatisch
            gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis
            Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser
            beim nächsten Besuch wiederzuerkennen.
          </p>{" "}
          <p>
            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
            Cookies informiert werden und Cookies nur im Einzelfall erlauben,
            die Annahme von Cookies für bestimmte Fälle oder generell
            ausschließen sowie das automatische Löschen der Cookies beim
            Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies
            kann die Funktionalität dieser Website eingeschränkt sein.
          </p>{" "}
          <p>
            Cookies, die zur Durchführung des elektronischen
            Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen
            erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind,
            werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der
            Websitebetreiber hat ein berechtigtes Interesse an der Speicherung
            von Cookies zur technisch fehlerfreien und optimierten
            Bereitstellung seiner Dienste. Soweit andere Cookies (z.B. Cookies
            zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese
            in dieser Datenschutzerklärung gesondert behandelt.
          </p>{" "}
          <p>
            <b>Server-Log-Dateien</b>
          </p>{" "}
          <p>
            Der Provider der Seiten erhebt und speichert automatisch
            Informationen in so genannten Server-Log-Dateien, die Ihr Browser
            automatisch an uns übermittelt. Dies sind:
          </p>{" "}
          <ul>
            <li>Browsertyp und Browserversion</li>{" "}
            <li>verwendetes Betriebssystem</li> <li>Referrer URL</li>{" "}
            <li>Uhrzeit der Serveranfrage</li> <li>IP-Adresse</li>{" "}
          </ul>
          <p>
            Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
            nicht vorgenommen.
          </p>{" "}
          <p>
            Die Rechtsgrundlage für die Verarbeitung der Daten ist Art. 6 Abs. 1
            lit. f DSGVO. Unser berechtigtes Interesse folgt aus den nachfolgend
            aufgelisteten Zwecken der Datenerhebung: Systemsicherheit und
            -stabilität.
          </p>{" "}
          <p>
            <b>Kontaktformular</b>
          </p>{" "}
          <p>
            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
            Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
            angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
            Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
            nicht ohne Ihre Einwilligung weiter.
          </p>{" "}
          <p>
            Die Verarbeitung der in das Kontaktformular eingegebenen Daten
            erfolgt somit ausschließlich auf Grundlage Ihrer Einwilligung (Art.
            6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit
            widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns.
            Die Rechtmäßigkeit der bis zum Widerruf erfolgten
            Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
          </p>{" "}
          <p>
            Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
            uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
            Speicherung widerrufen oder der Zweck für die Datenspeicherung
            entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
            Zwingende gesetzliche Bestimmungen “insbesondere
            Aufbewahrungsfristen“ bleiben unberührt.
          </p>{" "}
          <p>
            <b>4. Soziale Medien</b>
          </p>{" "}
          <p>
            <b>
              Inhalte teilen über Plugins (Facebook, Google+1, Twitter &amp;
              Co.)
            </b>
          </p>{" "}
          <p>
            Die Inhalte auf unseren Seiten können datenschutzkonform in sozialen
            Netzwerken wie Facebook, Twitter oder Google+ geteilt werden. Diese
            Seite nutzt dafür ein sogenanntes 2-Klick-Tool. Dieses Tool stellt
            den direkten Kontakt zwischen den Netzwerken und Nutzern erst dann
            her, wenn der Nutzer aktiv auf einen dieser Button klickt.
          </p>{" "}
          <p>
            Eine automatische Übertragung von Nutzerdaten an die Betreiber
            dieser Plattformen erfolgt durch dieses Tool nicht. Ist der Nutzer
            bei einem der sozialen Netzwerke angemeldet, erscheint bei der
            Nutzung der Social-Buttons von Facebook, Google+1, Twitter &amp; Co.
            ein Informations-Fenster, in dem der Nutzer den Text vor dem
            Absenden bestätigen kann.
          </p>{" "}
          <p>
            Unsere Nutzer können die Inhalte dieser Seite datenschutzkonform in
            sozialen Netzwerken teilen, ohne dass komplette Surf-Profile durch
            die Betreiber der Netzwerke erstellt werden.
          </p>{" "}
          <p>
            <b>6. Analyse Tools und Werbung</b>
          </p>{" "}
          <p>
            <b>Google Analytics</b>
          </p>{" "}
          <p>
            Diese Website nutzt Funktionen des Webanalysedienstes Google
            Analytics. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway,
            Mountain View, CA 94043, USA.
          </p>{" "}
          <p>
            Google Analytics verwendet so genannte "Cookies". Das sind
            Textdateien, die auf Ihrem Computer gespeichert werden und die eine
            Analyse der Benutzung der Website durch Sie ermöglichen. Die durch
            das Cookie erzeugten Informationen über Ihre Benutzung dieser
            Website werden in der Regel an einen Server von Google in den USA
            übertragen und dort gespeichert.
          </p>{" "}
          <p>
            Die Speicherung von Google-Analytics-Cookies erfolgt auf Grundlage
            von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
            berechtigtes Interesse an der Analyse des Nutzerverhaltens, um
            sowohl sein Webangebot als auch seine Werbung zu optimieren.
          </p>{" "}
          <p>
            <b>IP Anonymisierung</b>
          </p>{" "}
          <p>
            Wir haben auf dieser Website die Funktion IP-Anonymisierung
            aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von
            Mitgliedstaaten der Europäischen Union oder in anderen
            Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
            vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird
            die volle IP-Adresse an einen Server von Google in den USA
            übertragen und dort gekürzt. Im Auftrag des Betreibers dieser
            Website wird Google diese Informationen benutzen, um Ihre Nutzung
            der Website auszuwerten, um Reports über die Websiteaktivitäten
            zusammenzustellen und um weitere mit der Websitenutzung und der
            Internetnutzung verbundene Dienstleistungen gegenüber dem
            Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics
            von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen
            Daten von Google zusammengeführt.
          </p>{" "}
          <p>
            <b>Browser Plugin</b>
          </p>{" "}
          <p>
            Sie können die Speicherung der Cookies durch eine entsprechende
            Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
            darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
            Funktionen dieser Website vollumfänglich werden nutzen können. Sie
            können darüber hinaus die Erfassung der durch den Cookie erzeugten
            und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer
            IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch
            Google verhindern, indem Sie das unter dem folgenden Link verfügbare
            Browser-Plugin herunterladen und installieren:{" "}
            <u>
              <a
                href="https://tools.google.com/dlpage/gaoptout?hl=de"
                target="_blank"
              >
                https://tools.google.com/dlpage/gaoptout?hl=de
              </a>
            </u>
            .
          </p>{" "}
          <p>
            <b>Widerspruch gegen Datenerfassung</b>
          </p>{" "}
          <p>
            Sie können die Erfassung Ihrer Daten durch Google Analytics
            verhindern, indem Sie auf folgenden Link klicken. Es wird ein
            Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei
            zukünftigen Besuchen dieser Website verhindert:{" "}
            <u>Google Analytics deaktivieren</u>.
          </p>{" "}
          <p>
            Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics
            finden Sie in der Datenschutzerklärung von Google:{" "}
            <u>
              <a
                href="https://support.google.com/analytics/answer/6004245?hl=de"
                target="_blank"
              >
                https://support.google.com/analytics/answer/6004245?hl=de
              </a>
            </u>
            .
          </p>{" "}
          <p>
            <b>Auftragsdatenverarbeitung</b>
          </p>{" "}
          <p>
            Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung
            abgeschlossen und setzen die strengen Vorgaben der deutschen
            Datenschutzbehörden bei der Nutzung von Google Analytics vollständig
            um.
          </p>{" "}
          <p>
            <b>Demografische Merkmale bei Google Analytics</b>
          </p>{" "}
          <p>
            Diese Website nutzt die Funktion demografische Merkmale von Google
            Analytics. Dadurch können Berichte erstellt werden, die Aussagen zu
            Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese
            Daten stammen aus interessenbezogener Werbung von Google sowie aus
            Besucherdaten von Drittanbietern. Diese Daten können keiner
            bestimmten Person zugeordnet werden. Sie können diese Funktion
            jederzeit Ã¼ber die Anzeigeneinstellungen in Ihrem Google-Konto
            deaktivieren oder die Erfassung Ihrer Daten durch Google Analytics
            wie im Punkt Widerspruch gegen Datenerfassung dargestellt generell
            untersagen.
          </p>{" "}
          <p>
            <b>Google Analytics Remarketing</b>
          </p>{" "}
          <p>
            Unsere Websites nutzen die Funktionen von Google Analytics
            Remarketing in Verbindung mit den gerÃ¤teÃ¼bergreifenden Funktionen
            von Google AdWords und Google DoubleClick. Anbieter ist die Google
            Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
          </p>{" "}
          <p>
            Diese Funktion ermöglicht es die mit Google Analytics Remarketing
            erstellten Werbe-Zielgruppen mit den geräteübergreifenden Funktionen
            von Google AdWords und Google DoubleClick zu verknüpfen. Auf diese
            Weise können interessenbezogene, personalisierte Werbebotschaften,
            die in Abhängigkeit Ihres früheren Nutzungs- und Surfverhaltens auf
            einem Endgerät (z.B. Handy) an Sie angepasst wurden auch auf einem
            anderen Ihrer Endgeräte (z.B. Tablet oder PC) angezeigt werden.
          </p>{" "}
          <p>
            Haben Sie eine entsprechende Einwilligung erteilt, verknüpft Google
            zu diesem Zweck Ihren Web- und App-Browserverlauf mit Ihrem
            Google-Konto. Auf diese Weise können auf jedem Endgerät auf dem Sie
            sich mit Ihrem Google-Konto anmelden, dieselben personalisierten
            Werbebotschaften geschaltet werden.
          </p>{" "}
          <p>
            Zur Unterstützung dieser Funktion erfasst Google Analytics
            Google-authentifizierte IDs der Nutzer, die vorübergehend mit
            unseren Google-Analytics-Daten verknüpft werden, um Zielgruppen für
            die geräteübergreifende Anzeigenwerbung zu definieren und zu
            erstellen.
          </p>{" "}
          <p>
            Sie können dem geräteübergreifenden Remarketing/Targeting dauerhaft
            widersprechen, indem Sie personalisierte Werbung in Ihrem
            Google-Konto deaktivieren; folgen Sie hierzu diesem Link:{" "}
            <u>
              <a
                href="https://www.google.com/settings/ads/onweb/"
                target="_blank"
              >
                https://www.google.com/settings/ads/onweb/
              </a>
            </u>
            .
          </p>{" "}
          <p>
            Die Zusammenfassung der erfassten Daten in Ihrem Google-Konto
            erfolgt ausschließlich auf Grundlage Ihrer Einwilligung, die Sie bei
            Google abgeben oder widerrufen können (Art. 6 Abs. 1 lit. a DSGVO).
            Bei Datenerfassungsvorgängen, die nicht in Ihrem Google-Konto
            zusammengeführt werden (zB., weil Sie kein Google-Konto haben oder
            der Zusammenführung widersprochen haben) beruht die Erfassung der
            Daten auf Art. 6 Abs. 1 lit. f DSGVO. Das berechtigte Interesse
            ergibt sich daraus, dass der Websitebetreiber ein Interesse an der
            anonymisierten Analyse der Websitebesucher zu Werbezwecken hat.
          </p>{" "}
          <p>
            Weitergehende Informationen und die Datenschutzbestimmungen finden
            Sie in der Datenschutzerklärung von Google unter:{" "}
            <u>
              <a
                href="https://www.google.com/policies/technologies/ads/"
                target="_blank"
              >
                https://www.google.com/policies/technologies/ads/
              </a>
            </u>
            .
          </p>{" "}
          <p>
            <b>Google AdWords und Google Conversion-Tracking</b>
          </p>{" "}
          <p>
            Diese Website verwendet Google AdWords. AdWords ist ein
            Online-Werbeprogramm der Google Inc., 1600 Amphitheatre Parkway,
            Mountain View, CA 94043, United States (Google).
          </p>{" "}
          <p>
            Im Rahmen von Google AdWords nutzen wir das so genannte
            Conversion-Tracking. Wenn Sie auf eine von Google geschaltete
            Anzeige klicken wird ein Cookie für das Conversion-Tracking gesetzt.
            Bei Cookies handelt es sich um kleine Textdateien, die der
            Internet-Browser auf dem Computer des Nutzers ablegt. Diese Cookies
            verlieren nach 30 Tagen ihre Gültigkeit und dienen nicht der
            persönlichen Identifizierung der Nutzer. Besucht der Nutzer
            bestimmte Seiten dieser Website und das Cookie ist noch nicht
            abgelaufen, können Google und wir erkennen, dass der Nutzer auf die
            Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde.
          </p>{" "}
          <p>
            Jeder Google AdWords-Kunde erhält ein anderes Cookie. Die Cookies
            können nicht Ã¼ber die Websites von AdWords-Kunden nachverfolgt
            werden. Die mithilfe des Conversion-Cookies eingeholten
            Informationen dienen dazu, Conversion-Statistiken für AdWords-Kunden
            zu erstellen, die sich für Conversion-Tracking entschieden haben.
            Die Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre
            Anzeige geklickt haben und zu einer mit einem
            Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie
            erhalten jedoch keine Informationen, mit denen sich Nutzer
            persönlich identifizieren lassen. Wenn Sie nicht am Tracking
            teilnehmen möchten, können Sie dieser Nutzung widersprechen, indem
            Sie das Cookie des Google Conversion-Trackings über ihren
            Internet-Browser unter Nutzereinstellungen leicht deaktivieren. Sie
            werden sodann nicht in die Conversion-Tracking Statistiken
            aufgenommen.
          </p>{" "}
          <p>
            Die Speicherung von Conversion-Cookies erfolgt auf Grundlage von
            Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
            berechtigtes Interesse an der Analyse des Nutzerverhaltens, um
            sowohl sein Webangebot als auch seine Werbung zu optimieren.
          </p>{" "}
          <p>
            Mehr Informationen zu Google AdWords und Google Conversion-Tracking
            finden Sie in den Datenschutzbestimmungen von Google:{" "}
            <u>
              <a href="https://www.google.de/policies/privacy/" target="_blank">
                https://www.google.de/policies/privacy/
              </a>
            </u>
            .
          </p>{" "}
          <p>
            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
            Cookies informiert werden und Cookies nur im Einzelfall erlauben,
            die Annahme von Cookies für bestimmte Fälle oder generell
            ausschließen sowie das automatische Löschen der Cookies beim
            Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies
            kann die Funktionalität dieser Website eingeschränkt sein.
          </p>{" "}
          <p>
            <b>Google reCAPTCHA</b>
          </p>{" "}
          <p>
            Wir nutzen Google reCAPTCHA (im Folgenden reCAPTCHA) auf unseren
            Websites. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway,
            Mountain View, CA 94043, USA (Google).
          </p>{" "}
          <p>
            Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf unseren
            Websites (z.B. in einem Kontaktformular) durch einen Menschen oder
            durch ein automatisiertes Programm erfolgt. Hierzu analysiert
            reCAPTCHA das Verhalten des Websitebesuchers anhand verschiedener
            Merkmale. Diese Analyse beginnt automatisch, sobald der
            Websitebesucher die Website betritt. Zur Analyse wertet reCAPTCHA
            verschiedene Informationen aus (z.B. IP-Adresse, Verweildauer des
            Websitebesuchers auf der Website oder vom Nutzer getätigte
            Mausbewegungen). Die bei der Analyse erfassten Daten werden an
            Google weitergeleitet.
          </p>{" "}
          <p>
            Die reCAPTCHA-Analysen laufen vollständig im Hintergrund.
            Websitebesucher werden nicht darauf hingewiesen, dass eine Analyse
            stattfindet.
          </p>{" "}
          <p>
            Die Datenverarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
            DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse daran,
            seine Webangebote vor missbräuchlicher automatisierter Ausspähung
            und vor SPAM zu schützen.
          </p>{" "}
          <p>
            Weitere Informationen zu Google reCAPTCHA sowie die
            Datenschutzerklärung von Google entnehmen Sie folgenden Links:{" "}
            <u>
              <a
                href="https://www.google.com/intl/de/policies/privacy/"
                target="_blank"
              >
                https://www.google.com/intl/de/policies/privacy/
              </a>
            </u>{" "}
            und{" "}
            <u>
              <a
                href="https://www.google.com/recaptcha/intro/android.html"
                target="_blank"
              >
                https://www.google.com/recaptcha/intro/android.html
              </a>
            </u>
            .
          </p>{" "}
          <p>
            <b>7. Newsletter</b>
          </p>{" "}
          <p>
            <b>Newsletterdaten</b>
          </p>{" "}
          <p>
            Wenn Sie den auf der Website angebotenen Newsletter beziehen
            möchten, benötigen wir von Ihnen eine E-Mail-Adresse sowie
            Informationen, welche uns die Überprüfung gestatten, dass Sie der
            Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang des
            Newsletters einverstanden sind. Weitere Daten werden nicht bzw. nur
            auf freiwilliger Basis erhoben. Diese Daten verwenden wir
            ausschließlich für den Versand der angeforderten Informationen und
            geben diese nicht an Dritte weiter.
          </p>{" "}
          <p>
            Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen
            Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art.
            6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur Speicherung
            der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des
            Newsletters können Sie jederzeit widerrufen, etwa Ã¼ber den
            "Austragen"-Link im Newsletter. Die Rechtmäßigkeit der bereits
            erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
          </p>{" "}
          <p>
            Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten
            Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter
            gespeichert und nach der Abbestellung des Newsletters gelöscht.
            Daten, die zu anderen Zwecken bei uns gespeichert wurden (z.B.
            E-Mail-Adressen für den Mitgliederbereich) bleiben hiervon
            unberührt.
          </p>{" "}
          <p>
            <b>8. Plugins und Tools</b>
          </p>{" "}
          <p>
            <b>YouTube</b>
          </p>{" "}
          <p>
            Unsere Website nutzt Plugins der von Google betriebenen Seite
            YouTube. Betreiber der Seiten ist die YouTube, LLC, 901 Cherry Ave.,
            San Bruno, CA 94066, USA.
          </p>{" "}
          <p>
            Wenn Sie eine unserer mit einem YouTube-Plugin ausgestatteten Seiten
            besuchen, wird eine Verbindung zu den Servern von YouTube
            hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche
            unserer Seiten Sie besucht haben.
          </p>{" "}
          <p>
            Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie
            YouTube, Ihr Surfverhalten direkt Ihrem persönlichen Profil
            zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem
            YouTube-Account ausloggen.
          </p>{" "}
          <p>
            Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
            Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
            Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
          </p>{" "}
          <p>
            Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der
            Datenschutzerklärung von YouTube unter:{" "}
            <u>
              <a
                href="https://www.google.de/intl/de/policies/privacy"
                target="_blank"
              >
                https://www.google.de/intl/de/policies/privacy
              </a>
            </u>
            .
          </p>{" "}
          <p>
            <b>Google Web Fonts</b>
          </p>{" "}
          <p>
            Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
            genannte Web Fonts, die von Google bereitgestellt werden. Beim
            Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in
            ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
          </p>{" "}
          <p>
            Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu
            den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis
            darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde.
            Die Nutzung von Google Web Fonts erfolgt im Interesse einer
            einheitlichen und ansprechenden Darstellung unserer Online-Angebote.
            Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1
            lit. f DSGVO dar.
          </p>{" "}
          <p>
            Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine
            Standardschrift von Ihrem Computer genutzt.
          </p>{" "}
          <p>
            Weitere Informationen zu Google Web Fonts finden Sie unter{" "}
            <u>
              <a href="https://developers.google.com/fonts/faq" target="_blank">
                https://developers.google.com/fonts/faq
              </a>
            </u>{" "}
            und in der Datenschutzerklärung von Google:{" "}
            <u>
              <a
                href="https://www.google.com/policies/privacy/"
                target="_blank"
              >
                https://www.google.com/policies/privacy/
              </a>
            </u>
            .
          </p>{" "}
          <p />
        </section>
        <Footer />
      </div>
    );
  }
}

export default PageDatenschutz;
