import Penthouse1fp from "../img/wohnungen/penthouse1-floorplan.jpg";
import Penthouse2fp from "../img/wohnungen/Penthouse_Neu_1.jpg";
import Penthouse3fp from "../img/wohnungen/Penthouse_Neu_2.jpg";
import Wohnung1fp from "../img/wohnungen/wohnung1-floorplan.jpg";
import Wohnung2fp from "../img/wohnungen/wohnung2-floorplan.jpg";
import Wohnung3fp from "../img/wohnungen/wohnung3-floorplan.jpg";
import interior1 from "../img/wohnungen/interior1.jpg";
import interior2 from "../img/wohnungen/interior2.jpg";
import interior3 from "../img/wohnungen/interior3.jpg";
import interior4 from "../img/wohnungen/interior4.jpg";
import interior5 from "../img/wohnungen/interior5.jpg";
import interior6 from "../img/wohnungen/interior6.jpg";
import interior7 from "../img/wohnungen/interior7.jpg";
import interior1t from "../img/wohnungen/interior1-thumb.jpg";
import interior2t from "../img/wohnungen/interior2-thumb.jpg";
import interior3t from "../img/wohnungen/interior3-thumb.jpg";
import interior4t from "../img/wohnungen/interior4-thumb.jpg";
import interior5t from "../img/wohnungen/interior5-thumb.jpg";
import interior6t from "../img/wohnungen/interior6-thumb.jpg";
import interior7t from "../img/wohnungen/interior7-thumb.jpg";

import grund1 from '../img/neue_grundrisse/grund1.jpg';
import grund2 from '../img/neue_grundrisse/grund2.jpg';
import grund3 from '../img/neue_grundrisse/grund3.jpg';
import grund4 from '../img/neue_grundrisse/grund4.jpg';
import grund5 from '../img/neue_grundrisse/grund5.jpg';
import grund6 from '../img/neue_grundrisse/grund6.jpg';

const data = {
	geschosse: [
		{
			bezeichnung: 'Die Wohnungen im EngelsGarten II',
			splash: 'Vertriebsstart in Kürze',
			beschreibung:
				'Im EngelsGarten finden Sie Wohnungen ganz nach Ihrem Bedarf. Ob Erdgeschoss mit eigenem Garten oder Penthouse mit weitem Blick. Von 76 bis 129 Quadratmetern lassen die modernen Wohnungen keine Wünsche unerfüllt.',
			wohnungen: [
				{
					beschreibung: 'Weitblick entdecken',
					typ: 'Wohnung | EG inkl. Terrasse |  1. OG bis 3. OG mit Balkon',
					text:
						'Erleben Sie absolute Freiheit auf 122 Quadratmetern. Das gemütliche Penthouse mit Weitblick besticht durch moderne Aussttattung, sorgfältig ausgewählte Materialien und großzügige Aufteilung.',
					wohnflaeche: '122',
					zimmer: 3,

					zimmerDetail: [
						{
							text: 'Wohnen/Kochen',
							size: '43.91',
						},
						{
							text: 'Schlafen',
							size: '20.90',
						},
						{
							text: 'ABST.',
							size: '1.74',
						},
						{
							text: 'Bad',
							size: '11.47',
						},
						{
							text: 'Kind',
							size: '16.91',
						},

						{
							text: 'Diele',
							size: '11.93',
						},
						{
							text: 'VORR.',
							size: '1.82',
						},
						{
							text: 'WC',
							size: '3.35',
						},
						{
							text: 'Balkon 1/2',
							size: '9.74',
						},
						{
							text: 'Terasse 1/2',
							size: '9.75',
						},
					],
					bilder: [
						{
							original: grund1,
							thumbnail: grund1,
						},
						{
							original: interior1,
							thumbnail: interior1t,
						},
						{
							original: interior2,
							thumbnail: interior2t,
						},
						{
							original: interior3,
							thumbnail: interior3t,
						},
						{
							original: interior4,
							thumbnail: interior4t,
						},
						{
							original: interior5,
							thumbnail: interior5t,
						},
						{
							original: interior6,
							thumbnail: interior6t,
						},
						{
							original: interior7,
							thumbnail: interior7t,
						},
					],
				},
				{
					beschreibung: 'Freiheit spüren',
					typ: 'Wohnung | EG inkl. Terrasse | 1. OG bis 3. OG mit Balkon',
					text:
						'Erleben Sie absolute Freiheit auf 120 Quadratmetern. Das gemütliche Penthouse mit Weitblick besticht durch moderne Aussttattung, sorgfältig ausgewählte Materialien und großzügige Aufteilung.',
					wohnflaeche: '78',
					zimmer: 2,

					zimmerDetail: [
						{
							text: 'Wohnen/Kochen',
							size: '35.70',
						},
						{
							text: 'Schlafen',
							size: '20.36',
						},
						{
							text: 'Abstellraum',
							size: '1.71',
						},
						{
							text: 'Bad',
							size: '7.62',
						},

						{
							text: 'Diele',
							size: '4.72',
						},

						{
							text: 'Balkon 1/2',
							size: '8.02',
						},
						{
							text: 'Terasse 1/2',
							size: '8.02',
						},
					],
					bilder: [
						{
							original: grund2,
							thumbnail: grund2,
						},
						{
							original: interior1,
							thumbnail: interior1t,
						},
						{
							original: interior2,
							thumbnail: interior2t,
						},
						{
							original: interior3,
							thumbnail: interior3t,
						},
						{
							original: interior4,
							thumbnail: interior4t,
						},
						{
							original: interior5,
							thumbnail: interior5t,
						},
						{
							original: interior6,
							thumbnail: interior6t,
						},
						{
							original: interior7,
							thumbnail: interior7t,
						},
					],
				},
				{
					beschreibung: 'Ruhe genießen',
					typ: 'Wohnung | EG inkl. Terrasse | 1. OG bis 3. OG mit Balkon',
					text:
						'Auf großzügigen 195 Quadratmetern lässt das 4-Zimmer Wohnung keine Wünsche übrig. Ob Sauna, genug Platz für Ihren Fitnessraum oder der großen Terasse: Hier blicken Sie weit und fühlen sich wohl!',
					wohnflaeche: '129',
					zimmer: 4,

					zimmerDetail: [
						{
							text: 'Wohnen/Kochen',
							size: '44.28',
						},
						{
							text: 'Schlafen',
							size: '21.97',
						},
						{
							text: 'Abstellraum',
							size: '1.07',
						},
						{
							text: 'Bad',
							size: '10.62',
						},

						{
							text: 'Diele',
							size: '10.99',
						},
						{
							text: 'Kind 1',
							size: '12.04',
						},
						{
							text: 'Kind2',
							size: '12.62',
						},
						{
							text: 'WC',
							size: '4.25',
						},
						{
							text: 'Terasse 1/2',
							size: '6.08',
						},
						{
							text: 'Balkon 1/2',
							size: '8.85',
						}
					],
					bilder: [
						{
							original: grund3,
							thumbnail: grund3,
						},
						{
							original: interior1,
							thumbnail: interior1t,
						},
						{
							original: interior2,
							thumbnail: interior2t,
						},
						{
							original: interior3,
							thumbnail: interior3t,
						},
						{
							original: interior4,
							thumbnail: interior4t,
						},
						{
							original: interior5,
							thumbnail: interior5t,
						},
						{
							original: interior6,
							thumbnail: interior6t,
						},
						{
							original: interior7,
							thumbnail: interior7t,
						},
					],
				},
				{
					beschreibung: 'Komfort schätzen',
					typ: 'Penthouse',
					text:
						'Diese 117 Quadratmeter Wohnungen mit 3 Zimmern finden Sie im Erdgeschoss mit Garten oder im 1. und 2. Obergeschoss mit Balkon.',
					wohnflaeche: '117',
					gesamtflaeche: '116.68',
					zimmer: 3,

					zimmerDetail: [
						{
							text: 'Wohnen/Kochen',
							size: '42.82',
						},
						{
							text: 'Schlafen',
							size: '16.64',
						},
						{
							text: 'Abstellraum',
							size: '1.20',
						},
						{
							text: 'Bad',
							size: '11.47',
						},
						{
							text: 'Diele',
							size: '10.58',
						},
						{
							text: 'Kind',
							size: '12.20',
						},

						{
							text: 'WC',
							size: '3.35',
						},
						{
							text: 'Balkon 1/2',
							size: '6.44',
						},
						{
							text: 'Dachterrasse 1/2',
							size: '11.97',
						},
					],
					bilder: [
						{
							original: grund4,
							thumbnail: grund4,
						},
						{
							original: interior1,
							thumbnail: interior1t,
						},
						{
							original: interior2,
							thumbnail: interior2t,
						},
						{
							original: interior3,
							thumbnail: interior3t,
						},
						{
							original: interior4,
							thumbnail: interior4t,
						},
						{
							original: interior5,
							thumbnail: interior5t,
						},
						{
							original: interior6,
							thumbnail: interior6t,
						},
						{
							original: interior7,
							thumbnail: interior7t,
						},
					],
				},
				{
					beschreibung: 'Gemütlichkeit leben',
					typ: 'Penthouse',
					text:
						'Die gemütlichen 2-Zimmer Wohnungen überzeugen durch Ihre clevere Aufteilung und Ihrem großen Wohn- und Essbereich. Ob Erdgeschoss mit Garten oder 1. und 2. Obergeschoss mit Balkon: Wo wollen Sie wohnen?',
					wohnflaeche: '76',
					zimmer: 2,

					zimmerDetail: [
						{
							text: 'Wohnen/Kochen',
							size: '35.70',
						},
						{
							text: 'Schlafen',
							size: '15.17',
						},
						{
							text: 'Abstellraum',
							size: '1.71',
						},
						{
							text: 'Bad',
							size: '7.58',
						},
						{
							text: 'Diele',
							size: '6.11',
						},
						{
							text: 'WC',
							size: '1.64',
						},
						{
							text: 'Dachterasse 1/2',
							size: '8.06',
						},
					],
					bilder: [
						{
							original: grund5,
							thumbnail: grund5,
						},
						{
							original: interior1,
							thumbnail: interior1t,
						},
						{
							original: interior2,
							thumbnail: interior2t,
						},
						{
							original: interior3,
							thumbnail: interior3t,
						},
						{
							original: interior4,
							thumbnail: interior4t,
						},
						{
							original: interior5,
							thumbnail: interior5t,
						},
						{
							original: interior6,
							thumbnail: interior6t,
						},
						{
							original: interior7,
							thumbnail: interior7t,
						},
					],
				},
				{
					beschreibung: 'Exklusivität fühlen',
					typ: 'Penthouse',
					text: '',
					wohnflaeche: '123',
					zimmer: 3,

					zimmerDetail: [
						{
							text: 'Wohnen/Kochen',
							size: '45.60',
						},
						{
							text: 'Schlafen',
							size: '20.14',
						},
						{
							text: 'Bad',
							size: '10.62',
						},
						{
							text: 'Diele',
							size: '8.16',
						},
						{
							text: 'Kind',
							size: '15.99',
						},

						{
							text: 'VORR.ABST.',
							size: '2.21',
						},
						{
							text: 'WC',
							size: '4.25',
						},
						{
							text: 'Dachterasse 1/2',
							size: '8.87',
						},
						{
							text: 'Balkon 1/2',
							size: '7.33',
						},
					],
					bilder: [
						{
							original: grund6,
							thumbnail: grund6,
						},
						{
							original: interior1,
							thumbnail: interior1t,
						},
						{
							original: interior2,
							thumbnail: interior2t,
						},
						{
							original: interior3,
							thumbnail: interior3t,
						},
						{
							original: interior4,
							thumbnail: interior4t,
						},
						{
							original: interior5,
							thumbnail: interior5t,
						},
						{
							original: interior6,
							thumbnail: interior6t,
						},
						{
							original: interior7,
							thumbnail: interior7t,
						},
					],
				},
			],
		},
		{
			bezeichnung: 'Die Wohnungen im EngelsGarten I',
			splash: 'Vor Baubeginn bereits verkauft',
			beschreibung:
				'Im EngelsGarten finden Sie Wohnungen ganz nach Ihrem Bedarf. Ob Erdgeschoss mit eigenem Garten oder Penthouse mit weitem Blick. Von 76 bis 126 Quadratmetern lassen die modernen Wohnungen keine Wünsche unerfüllt.',
			wohnungen: [
				{
					beschreibung: 'Weitblick entdecken',
					typ: 'Penthouse',
					text:
						'Erleben Sie absolute Freiheit auf 120 Quadratmetern. Das gemütliche Penthouse mit Weitblick besticht durch moderne Aussttattung, sorgfältig ausgewählte Materialien und großzügige Aufteilung.',
					wohnflaeche: '117',
					zimmer: 3,

					zimmerDetail: [
						{
							text: 'Wohnen',
							size: '42.82',
						},
						{
							text: 'Schlafen',
							size: '16.64',
						},
						{
							text: 'Bad',
							size: '11.48',
						},
						{
							text: 'Kind',
							size: '12.2',
						},
						{
							text: 'Abstellraum',
							size: '1.2',
						},
						{
							text: 'Diele',
							size: '10.58',
						},
						{
							text: 'WC',
							size: '3.35',
						},
						{
							text: 'Balkon 1/2',
							size: '6.44',
						},
						{
							text: 'Terasse 1/2',
							size: '11.97',
						},
					],
					bilder: [
						{
							original: Penthouse1fp,
							thumbnail: Penthouse1fp,
						},
						{
							original: interior1,
							thumbnail: interior1t,
						},
						{
							original: interior2,
							thumbnail: interior2t,
						},
						{
							original: interior3,
							thumbnail: interior3t,
						},
						{
							original: interior4,
							thumbnail: interior4t,
						},
						{
							original: interior5,
							thumbnail: interior5t,
						},
						{
							original: interior6,
							thumbnail: interior6t,
						},
						{
							original: interior7,
							thumbnail: interior7t,
						},
					],
				},
				{
					beschreibung: 'Freiheit spüren',
					typ: 'Penthouse',
					text:
						'Erleben Sie absolute Freiheit auf 120 Quadratmetern. Das gemütliche Penthouse mit Weitblick besticht durch moderne Aussttattung, sorgfältig ausgewählte Materialien und großzügige Aufteilung.',
					wohnflaeche: '121',
					zimmer: 3,

					zimmerDetail: [
						{
							text: 'Wohnen',
							size: '45.6',
						},
						{
							text: 'Schlafen',
							size: '20.14',
						},
						{
							text: 'Bad',
							size: '10.62',
						},
						{
							text: 'Kind',
							size: '15.99',
						},
						{
							text: 'Abstellraum',
							size: '2.21',
						},
						{
							text: 'Diele',
							size: '8.16',
						},
						{
							text: 'WC',
							size: '4.25',
						},
						{
							text: 'Balkon 1/2',
							size: '7.33',
						},
						{
							text: 'Terasse 1/2',
							size: '6.3',
						},
					],
					bilder: [
						{
							original: Penthouse3fp,
							thumbnail: Penthouse3fp,
						},
						{
							original: interior1,
							thumbnail: interior1t,
						},
						{
							original: interior2,
							thumbnail: interior2t,
						},
						{
							original: interior3,
							thumbnail: interior3t,
						},
						{
							original: interior4,
							thumbnail: interior4t,
						},
						{
							original: interior5,
							thumbnail: interior5t,
						},
						{
							original: interior6,
							thumbnail: interior6t,
						},
						{
							original: interior7,
							thumbnail: interior7t,
						},
					],
				},
				{
					beschreibung: 'Ruhe genießen',
					typ: 'Penthouse',
					text:
						'Auf großzügigen 195 Quadratmetern lässt das 4-Zimmer Penthouse keine Wünsche übrig. Ob Sauna, genug Platz für Ihren Fitnessraum oder der großen Terasse: Hier blicken Sie weit und fühlen sich wohl!',
					wohnflaeche: '76',
					zimmer: 2,
					zimmerDetail: [
						{
							text: 'Wohnen',
							size: '35.7',
						},
						{
							text: 'Schlafen',
							size: '15.17',
						},
						{
							text: 'Bad',
							size: '7.58',
						},
						{
							text: 'Abstellraum',
							size: '1.71',
						},
						{
							text: 'Diele',
							size: '6.11',
						},
						{
							text: 'WC',
							size: '1.64',
						},
						{
							text: 'Terasse 1/2',
							size: '8.06',
						},
					],

					bilder: [
						{
							original: Penthouse2fp,
							thumbnail: Penthouse2fp,
						},
						{
							original: interior1,
							thumbnail: interior1t,
						},
						{
							original: interior2,
							thumbnail: interior2t,
						},
						{
							original: interior3,
							thumbnail: interior3t,
						},
						{
							original: interior4,
							thumbnail: interior4t,
						},
						{
							original: interior5,
							thumbnail: interior5t,
						},
						{
							original: interior6,
							thumbnail: interior6t,
						},
						{
							original: interior7,
							thumbnail: interior7t,
						},
					],
				},
				{
					beschreibung: 'Komfort schätzen',
					typ: 'Wohnung | EG inkl. Garten | 1. OG + 2. OG mit Balkon',
					text:
						'Diese 117 Quadratmeter Wohnungen mit 2 Zimmern finden Sie im Erdgeschoss mit Garten oder im 1. und 2. Obergeschoss mit Balkon.',
					wohnflaeche: '118',
					gesamtflaeche: '117,72',
					zimmer: 3,

					zimmerDetail: [
						{
							text: 'Wohnen',
							size: '43.91',
						},
						{
							text: 'Schlafen',
							size: '20.91',
						},
						{
							text: 'Bad',
							size: '11.48',
						},
						{
							text: 'Kind',
							size: '16.91',
						},
						{
							text: 'Abstellraum',
							size: '1.75',
						},
						{
							text: 'Diele',
							size: '11.93',
						},
						{
							text: 'Vorratsraum',
							size: '1.66',
						},
						{
							text: 'WC',
							size: '3.35',
						},
						{
							text: 'Balkon',
							size: '5.84',
						},
					],
					bilder: [
						{
							original: Wohnung1fp,
							thumbnail: Wohnung1fp,
						},
						{
							original: interior1,
							thumbnail: interior1t,
						},
						{
							original: interior2,
							thumbnail: interior2t,
						},
						{
							original: interior3,
							thumbnail: interior3t,
						},
						{
							original: interior4,
							thumbnail: interior4t,
						},
						{
							original: interior5,
							thumbnail: interior5t,
						},
						{
							original: interior6,
							thumbnail: interior6t,
						},
						{
							original: interior7,
							thumbnail: interior7t,
						},
					],
				},
				{
					beschreibung: 'Gemütlichkeit leben',
					typ: 'Wohnung | EG inkl. Garten | 1. OG + 2. OG mit Balkon',
					text:
						'Die gemütlichen 2-Zimmer Wohnungen überzeugen durch Ihre clevere Aufteilung und Ihrem großen Wohn- und Essbereich. Ob Erdgeschoss mit Garten oder 1. und 2. Obergeschoss mit Balkon: Wo wollen Sie wohnen?',
					wohnflaeche: '78',
					zimmer: 2,

					zimmerDetail: [
						{
							text: 'Wohnen',
							size: '35.70',
						},
						{
							text: 'Schlafen',
							size: '15.41',
						},
						{
							text: 'Abstellraum',
							size: '1.71',
						},
						{
							text: 'Bad',
							size: '7.59',
						},
						{
							text: 'Diele',
							size: '6.02',
						},
						{
							text: 'WC',
							size: '3.06',
						},
						{
							text: 'Balkon',
							size: '8.06',
						},
					],
					bilder: [
						{
							original: Wohnung2fp,
							thumbnail: Wohnung2fp,
						},
						{
							original: interior1,
							thumbnail: interior1t,
						},
						{
							original: interior2,
							thumbnail: interior2t,
						},
						{
							original: interior3,
							thumbnail: interior3t,
						},
						{
							original: interior4,
							thumbnail: interior4t,
						},
						{
							original: interior5,
							thumbnail: interior5t,
						},
						{
							original: interior6,
							thumbnail: interior6t,
						},
						{
							original: interior7,
							thumbnail: interior7t,
						},
					],
				},
				{
					beschreibung: 'Exklusivität fühlen',
					typ: 'Wohnung | EG inkl. Garten | 1. OG + 2. OG mit Balkon',
					text: '',
					wohnflaeche: '126',
					zimmer: 4,
					zimmerDetail: [
						{
							text: 'Wohnen',
							size: '44.28',
						},
						{
							text: 'Schlafen',
							size: '21.97',
						},
						{
							text: 'Bad',
							size: '10.62',
						},
						{
							text: 'Kind 1',
							size: '12.04',
						},
						{
							text: 'Kind 2',
							size: '12.62',
						},
						{
							text: 'Abstellraum',
							size: '1.07',
						},
						{
							text: 'Diele',
							size: '10.99',
						},
						{
							text: 'Vorratsraum',
							size: '2.21',
						},
						{
							text: 'WC',
							size: '4.25',
						},
						{
							text: 'Balkon 1/2',
							size: '6.27',
						},
					],


					bilder: [
						{
							original: Wohnung3fp,
							thumbnail: Wohnung3fp,
						},
						{
							original: interior1,
							thumbnail: interior1t,
						},
						{
							original: interior2,
							thumbnail: interior2t,
						},
						{
							original: interior3,
							thumbnail: interior3t,
						},
						{
							original: interior4,
							thumbnail: interior4t,
						},
						{
							original: interior5,
							thumbnail: interior5t,
						},
						{
							original: interior6,
							thumbnail: interior6t,
						},
						{
							original: interior7,
							thumbnail: interior7t,
						},
					],
				},
			],
		},
	],
};
export default data;
