import React, { Component } from "react";
import Profil from "../img/kontakt/profil.png";
import Profil2 from "../img/kontakt/profil2.jpg";

import FalkLogo from "../img/falk.jpg";

class Kontakt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: ""
    };
  }

  send = event => {
    event.preventDefault();
    const data = {
      email: this.state.email,
      name: this.state.name,
      message: this.state.message
    };

    fetch("http://engelsgarten.12-05.de/mailer.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: JSON.stringify(data)
    }).then(response => {
      console.log(response);
      this.setState({
        sent: true
      });
    });
  };

  handleChange(event) {
    this.setState(
      {
        [event.target.name]: event.target.value
      },
      () => {
        if (this.state.email && this.state.name && this.state.message) {
          this.setState({
            formValid: true
          });
        }
      }
    );
  }
  render() {
    const { name, email, message } = this.state;
    return (
      <section id="kontakt">
        <h2>
          Ihre Ansprechpartner
          <br />
          <span className="subline">Qualität als Versprechen.</span>
        </h2>
        <aside>
          <img
            style={{ marginTop: "40px", maxWidth: "80%" }}
            alt="Falk"
            src={FalkLogo}
          />
        </aside>
        <div className="content">
          Als bundesweit agierendes Immobilienunternehmen betreut Sie die FALC
          Immobilien GmbH & Co. KG ganzheitlich und individuell. Neben dem Kauf
          unterstützt FALC Immobilien auch bei der Vermietung und Verwaltung von
          privaten und gewerblichen Objekten. Höchste Kundenzufriedenheit steht
          für FALC Immobilien stets an erster Stelle.
        </div>
        <aside />
        <div className="content">
          <div className="fifty-fifty">
            <div>
              <img alt="Profilbild" src={Profil2} />
              <strong>
                Ihr Ansprechpartner
                <br /> für technische Fragen:
              </strong>
              <br />
              Herr Hafiz Balija
              <br /> Tel: <a href="tel:02122401777">0212 / 2401777</a>
              <br />
              Mobil: <a href="tel:01632604091">0163/2 60 40 91</a>
              <br />
              <a href="mailto:hafiz.balija@falcimmo.de">
                hafiz.balija@falcimmo.de
              </a>
              <br />
            </div>
            <div>
              <img alt="Profilbild" src={Profil} />
              <strong>
                Ihre Ansprechpartnerin
                <br />
                für allgemeine Fragen:
              </strong>
              <br />
              Frau Sabine Littera
              <br /> Tel: <a href="tel:02122401777">0212 / 2401777</a>
              <br />
              Mobil: <a href="tel:01624310605">0162/431 0 605</a>
              <br />
              <a href="mailto:sabine.littera@falcimmo.de">
                sabine.littera@falcimmo.de
              </a>
            </div>
          </div>
          {!this.state.sent ? (
            <div>
              <h3>Kontaktformular</h3>
              <form onSubmit={event => this.send(event)}>
                <label>
                  Ihr Name
                  <input
                    onChange={event => this.handleChange(event)}
                    type="text"
                    name="name"
                    value={name}
                  />
                </label>
                <label>
                  Ihre E-Mail-Adresse
                  <input
                    onChange={event => this.handleChange(event)}
                    type="text"
                    name="email"
                    value={email}
                  />
                </label>
                <label>
                  Ihre Nachricht
                  <textarea
                    onChange={event => this.handleChange(event)}
                    name="message"
                    value={message}
                  />
                </label>
                <input
                  disabled={!this.state.formValid}
                  type="submit"
                  value="Abschicken"
                />
              </form>
            </div>
          ) : (
              <div className="success">
                {" "}
              Ihre Nachricht wurde erfolgreicht versendet.
              </div>
            )}
          <div style={{ clear: "both" }} />
        </div>
      </section>
    );
  }
}

export default Kontakt;
