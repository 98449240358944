import React, { Component } from "react";
class Lage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <section id="lage">
        <h2>Lage</h2>
        <div className="lage-map">
          <iframe
            title="Karte"
            className="mapframe"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2500.117280902255!2d7.067559251780047!3d51.19849057948526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b8d40c86e8ef95%3A0xd90bbea416fb59b7!2sN%C3%BCmmener+Str.+41%2C+42653+Solingen!5e0!3m2!1sde!2sde!4v1553071061047"
          />
          <div className="lage-adresse">
            <strong>Adresse</strong>
            <br />
            <span>Nümmener Str. 41-43</span>
            <br />
            <span>42653 Solingen</span>
            <br />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://goo.gl/maps/ExJiCEzUbL22"
            >
              Google Maps
            </a>
          </div>
        </div>
      </section>
    );
  }
}

export default Lage;
