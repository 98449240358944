import React, { Component } from "react";
import Carousel from "nuka-carousel";
import Bild1 from "../img/umfeld/bild1.jpg";
import Bild2 from "../img/umfeld/bild2.jpg";
import Bild3 from "../img/umfeld/bild3.jpg";
import Bild4 from "../img/umfeld/bild4.jpg";
import Bild5 from "../img/umfeld/bild5.jpg";
import Bild6 from "../img/umfeld/bild6.jpg";
import Bild7 from "../img/umfeld/bild7.jpg";
import Bild8 from "../img/umfeld/bild8.jpg";

class Umfeld extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <section id="umfeld">
        <h2>
          Charmantes Gräfrath
          <br />
          <span className="subline">feine Lebensqualität.</span>
        </h2>
        <div className="content">
          <h3>Für viele ist Gräfrath der schönste Stadtteil Solingens. </h3>
          <p>
            Werden Sie Teil der Gemeinde und lernen Sie den historischen
            Markplatz, die idyllische Natur und die sehr gute Verkehrsanbindung
            – auch zur naheliegenden Autobahn lieben.
          </p>
          <p>
            Der EngelsGarten liegt zentral und zugleich in einer ruhigen
            Nebenstraße. Sämtliche Geschäfte des täglichen Bedarfs sowie
            Schulen, Kindergärten, Ärzte und Banken, kulturelle Einrichtungen,
            Cafés und Restaurants finden Sie vor Ort. Sie wohnen in grüner
            Umgebung mit schnellem Zugang zur Natur und der beliebten
            Korkenziehertrasse, die per Pedes oder Rad Solingens Stadtteile
            verbindet.
          </p>
        </div>
        <Carousel
          renderCenterLeftControls={({ previousSlide }) => (
            <div className="prev" onClick={previousSlide} />
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <div className="next" onClick={nextSlide} />
          )}
          renderBottomRightControls={({ nextSlide, previousSlide }) => {
            return <div />;
          }}
        >
          <img alt="Bild" src={Bild1} />
          <img alt="Bild" src={Bild2} />
          <img alt="Bild" src={Bild3} />
          <img alt="Bild" src={Bild4} />
          <img alt="Bild" src={Bild5} />
          <img alt="Bild" src={Bild6} />
          <img alt="Bild" src={Bild7} />
          <img alt="Bild" src={Bild8} />
        </Carousel>
      </section>
    );
  }
}

export default Umfeld;
