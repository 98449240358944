import React, { Component } from "react";
import Share from "./Share";
import Fade from "react-reveal";

class Intro extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <section id="intro">
        <h2>
          Exklusiv wohnen
          <br />
          <span className="subline">außergewöhnlich leben.</span>
        </h2>
        <Share />
        <div className="content">
          <h3>Willkommen im EngelsGarten, wo Lebensträume wahr werden.</h3>
          <p>
            Genießen Sie außergewöhnliche Architektur, eine gehobene, luxuriöse
            Ausstattung und eine sorgfältige Materialauswahl, die keine Wünsche
            offen lassen.
          </p>
          <p>
            <strong>
              Im EngelsGarten leben Sie in dem sehr begehrten Stadtteil Gräfrath
              in Solingen.
            </strong>
          </p>
          <p>
            Große Fensterfronten und hohe Decken in den exklusiven
            Eigentumswohnungen sorgen für ein lichtdurchflutetes
            Wohlfühl-Ambiente mit individueller Note.
          </p>

          <nav>
            <h3 className="sub">Entdecken Sie</h3>
            <ul>
              <li>
                <a href="#gruende">10 Gründe den EngelsGarten zu lieben</a>
              </li>

              <li>
                <a href="#ausstattung">die anspruchsvolle Ausstattung</a>
              </li>
              <li>
                <a href="#geschosse">die luxuriösen Wohnungen zur Auswahl</a>
              </li>
              <li>
                <a href="#umfeld">die Lage im wunderschönen Gräfrath</a>
              </li>

              <li>
                <a href="#downloads">wichtige Downloads</a>
              </li>
              <li>
                <a href="#kontakt">Ihre persönlichen Ansprechpartner</a>
              </li>
            </ul>
          </nav>
        </div>
      </section>
    );
  }
}

export default Intro;
