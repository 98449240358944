import React, { Component } from "react";
import Carousel from "nuka-carousel";
import grund1 from "../img/wohnungen/Visual_1.jpg";
import grund2 from "../img/gruende/grund2.jpg";
import grund3 from "../img/gruende/grund3.jpg";
import grund4 from "../img/gruende/grund4.jpg";
import grund5 from "../img/wohnungen/Visual_2.jpg";
import grund6 from "../img/gruende/grund6.jpg";
import grund7 from "../img/wohnungen/Visual_3.jpg";
import grund8 from "../img/gruende/grund8.jpg";
import grund9 from "../img/wohnungen/Visual_4.jpg";
import grund10 from "../img/wohnungen/Visual_5.jpg";

const slides = [
  {
    text: "Großzügige Wohnungsarchitektur, die neue Maßstäbe in Solingen setzt",
    image: grund1
  },
  {
    text: "Hohe Decken und bodentiefe Fenster für eine offene Lebenskultur",
    image: grund2
  },
  {
    text: "Gehobene Ausstattung mit Markenprodukten für Bad und Küche",
    image: grund3
  },
  {
    text: "Exklusive Penthouse-Wohnungen mit Sauna und Jacuzzi auf dem Balkon",
    image: grund4
  },
  {
    text: "Energetische Bauweise für nachhaltige Einsparungen",
    image: grund5
  },
  {
    text: "Alle Räume mit edlem Parkett und hochwertigen Türen ausgestattet",
    image: grund6
  },
  {
    text: "Großzügige Terrassen oder Balkone mit ruhiger Südlage",
    image: grund7
  },
  {
    text: "Fußläufige Nähe zum historischen Marktplatz von Solingen Gräfrath",
    image: grund8
  },
  {
    text:
      "Grüne Lage in ruhiger Seitenstraße und zugleich guter Autobahnanbindung (A46)",
    image: grund9
  },
  {
    text:
      "Große Tiefgarage mit ausreichend Parkboxen und extrabreiter Einfahrt",
    image: grund10
  }
];

class Gruende extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <section id="gruende">

        <Carousel
          renderCenterLeftControls={({ previousSlide }) => (
            <div className="prev" onClick={previousSlide} />
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <div className="next" onClick={nextSlide} />
          )}
          renderBottomRightControls={({ nextSlide, previousSlide }) => {
            return <div />;
          }}
        >
          {slides.map((slide, idx) => {
            return (
              <div key={idx} className="slide">
                <img alt={slide.text} src={slide.image} />
                <div className="slide-caption">{slide.text}</div>
              </div>
            );
          })}
        </Carousel>
      </section>
    );
  }
}

export default Gruende;
