import React, { Component } from "react";
import Logo from "../img/logo.png";
import { Link } from "react-router-dom";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <header>
        <Link to="/">
          <img src={Logo} alt="Logo" rel="Logo" />
        </Link>
        <a href="tel:02122401777" className="tel">
          <span className="icon" />
          0212 / 2401777
        </a>
      </header>
    );
  }
}

export default Header;
