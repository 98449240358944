import React, { Component } from "react";
import Wohnung1 from "../img/gruende/grund2.jpg";
import Wohnung2 from "../img/neue_intro/intro4.jpg";
import pdf from '../img/DEU_FALC-SL-15832_001_lang_expose.pdf'

class Downloads extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <section id="downloads">
        <h2>Downloads</h2>
        <div className="download-grid">
          <a
            href={pdf}
            className="download"
            target='_blank'
          >
            <img alt="Download" src={Wohnung1} />
            <span className="download-title">Exposé</span>
            <div className="download-icon" />
          </a>
          <a
            href="http://engelsgarten.12-05.de/EngelsGarten_baubeschreibung.pdf"
            className="download"

          >
            <img style={{ height: "180px" }} alt="Download" src={Wohnung2} />
            <span className="download-title">Baubeschreibung</span>
            <div className="download-icon" />
          </a>
        </div>
      </section>
    );
  }
}

export default Downloads;
