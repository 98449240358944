import React, { Component } from "react";
import Geschoss from "./wohnungen/geschoss";
import data from "../data/wohnungen.js";

class Obergeschoss extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        {data.geschosse.map((geschoss, idx) => {
          return <Geschoss key={idx} data={geschoss} />;
        })}
      </div>
    );
  }
}

export default Obergeschoss;
