import React, { Component } from "react";
import Carousel from "nuka-carousel";
import slide0 from "../img/intro/slide0.jpg";
import slide1 from "../img/neue_intro/intro4.jpg";
import slide2 from "../img/intro/slide2.jpg";
import slide3 from "../img/intro/slide3.jpg";
import slide4 from "../img/neue_intro/intro1.jpg";
import SVGLogo from "../img/logo.svg";
class Hero extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <section id="hero">
        <div className="hero-inner">
          <img className="svg-logo" src={SVGLogo} />
          <br />
          <a href="#intro" className="btn btn-white">
            Mehr erfahren
          </a>
        </div>{" "}
        <div className="hero-slidewrapper">
          <Carousel
            transitionMode={"fade"}
            autoplay={true}
            speed={2000}
            renderCenterLeftControls={({ previousSlide }) => ""}
            renderCenterRightControls={({ nextSlide }) => ""}
            renderBottomRightControls={({ nextSlide, previousSlide }) => {
              return (
                <div>
                  <div className="prev" onClick={previousSlide} />
                  <div className="next" onClick={nextSlide} />
                </div>
              );
            }}
          >
            <div
              className="hero-slide"
              style={{ backgroundImage: "url(" + slide4 + ")" }}
            />
            <div
              className="hero-slide"
              style={{ backgroundImage: "url(" + slide0 + ")" }}
            />
            <div
              className="hero-slide"
              style={{ backgroundImage: "url(" + slide1 + ")" }}
            />
            <div
              className="hero-slide"
              style={{ backgroundImage: "url(" + slide2 + ")" }}
            />
            <div
              className="hero-slide"
              style={{ backgroundImage: "url(" + slide3 + ")" }}
            />
          </Carousel>
        </div>
        <div className="black-overlay" />
      </section>
    );
  }
}

export default Hero;
