import React, { Component } from "react";

class Wohnung extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overlay: false
    };
  }
  render() {
    const { data } = this.props;
    return (
      <div>
        <div className="wohnung">
          <img alt="Vorschau" src={data.bilder[0].thumbnail} />
          <div className="wohnung-inner">
            <h3>{data.beschreibung}.</h3>
            <div className="type">{data.typ}</div>
            <div>ca. {data.wohnflaeche} m²</div>
            <div>{data.zimmer} Zimmer</div>
            {data.kaufpreis && <div>ab {data.kaufpreis.toLocaleString()} € </div>}

            <div className="arrow">Details anzeigen</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Wohnung;
