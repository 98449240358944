import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

class PageImpressum extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Header />
        <section>
          <h3>Impressum</h3>
          <br />
          <p>
            {" "}
            <strong>Angaben gemäß § 5 TMG</strong>
            <br />
            HTN Immobilien Management GmbH
            <br />
            Höher Straße 4<br /> 42655 Solingen
          </p>
          <p>
            <strong>Kontakt</strong>
            <br />
            Mobil: <a href="tel:01624310605">0162/431 0 605</a>
            <br />
          </p>
          <p>
            <p>
              <strong>Gericht</strong>
              <br />
              Amtsgericht Wuppertal
              <br />
              HRB 28131
            </p>

            <p>
              Verantwortlicher i.S.d. Presserechts und §§ 5 TMG, 55 RStV
              <br />
              Bestellt als Geschäftsführer:
              <br />
              Littera, Sabine
            </p>

            <p>
              Urheberschaft (§ 13 UrhG) der verwendeten Fotos Bilder aus
              Solingen Gräfrath Copyright © Christian Beier
              www.beier-photographie.de
            </p>

            <p>
              Bild „Stau auf der deutschen Autobahn“ #959613320 Copyright ©
              Getty Images International www.istock.de
            </p>
          </p>
          <p>
            <strong>
              Haftungsausschluss (Disclaimer) – Haftung für Inhalte
            </strong>
            <br />
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
            auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
            §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
            verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
            überwachen oder nach Umständen zu forschen, die auf eine
            rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung
            oder Sperrung der Nutzung von Informationen nach den allgemeinen
            Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
            jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
            Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
            Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
          </p>

          <p>
            <strong>Haftung für Links</strong>
            <br />
            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
            Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            der Seiten verantwortlich. Die verlinkten Seiten wurden zum
            Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten
            Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
            nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
            derartige Links umgehend entfernen.
          </p>
        </section>
        <Footer />
      </div>
    );
  }
}

export default PageImpressum;
